<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
import { gsap } from "gsap";

import mediaQueryBreakpoints from "./utilities/composition/helpers/mediaQueryBreakpoints";
import deviceDetection from "./utilities/composition/helpers/deviceDetection";

import { useStore } from "vuex";

import { ref, watchEffect, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "App",
  setup() {
    const { isMobileAny } = deviceDetection();
    // const appDom = document.querySelector("#app");
    const store = useStore();
    const DOMLOADED = ref(false);
    const WINLOAD = ref(false);

    let time = 0.5;
    function beforeEnter(el) {
      gsap.set(el, {
        opacity: 0,
      });
    }
    function enter(el, done) {
      gsap.to(el, {
        duration: time,
        opacity: 1,
        onComplete: done,
      });
    }
    function leave(el, done) {
      gsap.to(el, {
        duration: time,
        opacity: 0,
        onComplete: done,
      });
    }

    function setScreenState() {
      let wW = window.innerWidth;
      if (wW <= mediaQueryBreakpoints.small) {
        store.commit("setScreenState", "small");
        return;
      }
      if (wW <= mediaQueryBreakpoints.medium) {
        store.commit("setScreenState", "medium");
        return;
      }
      if (wW <= mediaQueryBreakpoints.large) {
        store.commit("setScreenState", "large");
        return;
      }
      if (wW <= mediaQueryBreakpoints.xlarge) {
        store.commit("setScreenState", "xlarge");
        return;
      } else {
        store.commit("setScreenState", "xxlarge");
        return;
      }
    }
    onBeforeUnmount(() => {
      window.removeEventListener("resize", setScreenState);
    });

    onMounted(() => {
      setScreenState();
      window.addEventListener("resize", setScreenState);

      if (isMobileAny()) {
        document.body.classList.add("is-mobile");
        store.commit("setIsMobile", true);
      }

      window.addEventListener("load", () => {
        WINLOAD.value = true;
      });
      document.addEventListener("DOMContentLoaded", () => {
        DOMLOADED.value = true;
      });
    });

    watchEffect(() => {
      if (store.state.isOverlayOpen) {
        // document.body.style.overflow = "hidden";
      } else {
        // document.body.style.overflow = "auto";
      }
    });
    return {
      DOMLOADED,
      store,
      beforeEnter,
      enter,
      leave,
    };
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 0;

  visibility: hidden;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import store from "../store/store";
import prefixPath from "../utilities/composition/helpers/prefixPath";


function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/page/${view}.vue`);
}

const publicPath = process.env.BASE_URL;
const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
      },
    ],
  },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  {
    path: "/:pathMatch(.*)",
    name: "bad-not-found",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
      },
    ],
  },
  //HOME
  {
    path: publicPath,
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageHome"),
        },
      },
    ],
  },
  //ABOUT
  {
    path: publicPath + "ueber",

    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageUeber"),
        },
      },
    ],
  },
  //MENU
  {
    path: publicPath + "menu",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageMenu"),
        },
      },
    ],
  },
  // RESERVE
  {
    path: publicPath + "reservieren",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageReservieren"),
        },
      },
    ],
  },
  // KONTAKT
  {
    path: publicPath + "kontakt",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageKontakt"),
        },
      },
    ],
  },
  // DATENSCHUTZ
  {
    path: publicPath + "datenschutz",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageDatenschutz"),
        },
      },
    ],
  },
  // IMPRESSUM
  {
    path: publicPath + "impressum",
    component: loadView("PageAppDefault"),
    children: [
      {
        path: "",
        components: {
          helper: loadView("PageImpressum"),
        },
      },
    ],
  },
];

const router = createRouter({
  base: process.env.publicPath,
  history: createWebHistory(),
  routes, // short for `routes: routes`

  scrollBehavior(to, from, savedPosition) {
    // savedPosition

    // always scroll to top
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to !== from) {
        return { top: 0 };
      }
    }
  },
});

// router.beforeEach((to, from, next) => {
//   if (checkPath(to, from)) {
//     if (to.meta.middleware) {
//       // const middleware = require(`@/middleware/${to.meta.middleware}`);
//       const middleware = require(`@/middleware/auth.js`);
//       if (middleware) {
//         store.commit("setLastNext", to);
//         middleware.default(next, store);
//       } else {
//         console.error("NEXT");
//         next();
//       }
//     } else {
//       console.error("NEXT");
//       next();
//     }
//   } else {
//     return false;
//   }
// });

router.afterEach((to) => {
  store.commit("setActiveRoute", to.href);
  if (to.href == prefixPath("brand")) {
    // store.commit("setOverlayState", true);
  } else {
    // store.commit("setOverlayState", false);
  }
});

router.resolve({
  name: "bad-not-found",
  params: { pathMatch: "not/found" },
}).href; // '/not%2Ffound'
router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"] },
}).href; // '/not/found'

export default router;
